import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const PolicyTitle = styled.h1`
  color: var(--primary-color);
`;

const PolicySection = styled.section`
  margin-bottom: 2rem;
`;

const DataDeletion = () => {
  return (
    <PolicyContainer>
      <PolicyTitle>User Data Deletion Policy</PolicyTitle>
      <PolicySection>
        <p>If you wish to delete your personal data associated with the PupStart app, follow these steps:</p>
        <ol>
          <li>Go to the PupStart app and navigate to Settings.</li>
          <li>Find the "Delete My Account" option.</li>
          <li>Confirm your request, and your data will be removed.</li>
        </ol>
      </PolicySection>
      <PolicySection>
        <h2>Alternative Method</h2>
        <p>Alternatively, you can request data deletion via email:</p>
        <p>Email us at: <strong>hello@force.codes</strong></p>
        <p>We will process your request within 7 business days.</p>
      </PolicySection>
    </PolicyContainer>
  );
};

export default DataDeletion; 
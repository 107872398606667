import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Products from './components/Products';
import FeatureSection from './components/FeatureSection';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import DataDeletion from './components/DataDeletion';
import ExternalRedirect from './components/ExternalRedirect';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <About />
              <FeatureSection />
              <Products />
            </>
          } />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/data-deletion" element={<DataDeletion />} />
          
          {/* Social Media Redirects */}
          <Route path="/pupstart/instagram" element={<ExternalRedirect to="https://www.instagram.com/pupdateorg" />} />
          <Route path="/pupstart/tiktok" element={<ExternalRedirect to="https://www.tiktok.com/@pupdateapp" />} />
          <Route path="/pupstart/x" element={<ExternalRedirect to="https://www.instagram.com/pupdateorg" />} />
          <Route path="/pupstart/youtube" element={<ExternalRedirect to="https://www.instagram.com/pupdateorg" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;